<template>
  <div class="row">
    <div class="col-12">
      <ibox :title="'Sprzedaż promocji statystyka'">
        <template slot="content" slot-scope="{ setLoading }">
          <div class="mb-2">
            <small
              >Statystyka pokazuje sprzedaż promocji. <br />W
              <code>Rodzaj promocji</code> należy wybrać czy promocja polegała
              na obniżeniu o x% produktu czy produkt był dodawany za 1 zł.
              <br />W <code>Zakres</code> należy wybrać czy promocja obejmowała
              kategorie czy poszczególne produkty, brak wpisania produktu lub
              kategri spowoduje badanie na podstawie wszystkich produktów.
              <br />W <code>Procent (%)</code> należy wpisać samą liczbę o ile %
              była obniżona cena produktu. <br />Na samym końcu należy wybrać
              okres trwania promocji.
            </small>
          </div>
          <Promotions @loading="setLoading" />
        </template>
      </ibox>
    </div>
  </div>
</template>

<script>
import Ibox from "@/components/base/Ibox";
import Promotions from "@/components/Statistics/Sales/Promotions/PromotionsSale.vue";

export default {
  name: "PromotionsSaleStatistics",
  components: {
    Ibox,
    Promotions
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
