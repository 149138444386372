<template>
  <div>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <input
        autocomplete="off"
        name="hidden"
        type="text"
        style="display: none"
      />
      <div class="row">
        <div class="col-12 mb-2">
          <div class="mb-2">{{ $t("promotion_type") }} :</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              v-model="radioPromotion"
              v-bind:value="percent"
              v-on:change="radioTypePercent"
            />
            <label class="form-check-label" for="inlineRadio1">{{
              $t("percent")
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              v-model="radioPromotion"
              v-bind:value="gratis"
              v-on:change="radioTypeGratis"
            />
            <label class="form-check-label" for="inlineRadio2">{{
              $t("product_free")
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio3"
              v-model="radioPromotion"
              v-bind:value="none"
              v-on:change="radioTypeNone"
            />
            <label class="form-check-label" for="inlineRadio3">{{
              $t("no_promotions")
            }}</label>
          </div>
        </div>
        <div class="col-12 mb-2">
          <div class="mb-2">{{ $t("range") }}:</div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioProductOrCategory"
              id="radioProductOrCategory1"
              v-model="radioProductOrCategory"
              v-bind:value="categories"
              v-on:change="radioRange"
            />
            <label class="form-check-label" for="radioProductOrCategory1">{{
              $t("categories")
            }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioProductOrCategory"
              id="radioProductOrCategory2"
              v-model="radioProductOrCategory"
              v-bind:value="products"
              v-on:change="radioRange"
            />
            <label class="form-check-label" for="radioProductOrCategory2">{{
              $t("products")
            }}</label>
          </div>
          <hr />
        </div>
        <div
          v-if="radioProductOrCategory === 0"
          class="form-group col-sm-4"
          :class="{ 'has-error': errors.has('categoryId') }"
        >
          <label>
            {{ $t("categories") }}
            <small>({{ $t("optional").toLowerCase() }})</small>
          </label>
          <v-select
            name="categoryId"
            multiple
            v-model="items.categoryId"
            :label="labelSelectCategory"
            :options="categoryList"
            :reduce="category => category.id"
          >
          </v-select>
          <small v-show="errors.has('categoryId')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div
          v-if="radioProductOrCategory === 1"
          class="form-group col-sm-4"
          :class="{ 'has-error': errors.has('productSymbol') }"
        >
          <label>
            {{ $t("products") }}
            <small>({{ $t("optional").toLowerCase() }})</small>
          </label>
          <v-select
            name="productSymbol"
            multiple
            v-model="items.productSymbol"
            label="name"
            :options="productList"
            :reduce="product => product.plum"
          >
          </v-select>
          <small v-show="errors.has('categoryId')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div v-if="radioPromotion === 0" class="form-group col-sm-2">
          <label> {{ $t("percent").toLowerCase() }} (%) </label>
          <input
            class="form-control"
            type="number"
            min="0"
            max="99"
            placeholder="Liczba"
            name="percent"
            v-model="items.percent"
            v-validate="'required'"
          />
          <small v-show="errors.has('percent')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div
          class="form-group col-sm-3"
          :class="{ 'has-error': errors.has('dateStart') }"
        >
          <label>
            {{ $t("start_date") }}
          </label>
          <date-picker
            class="NeoDataPicker"
            v-model="items.dateStart"
            :config="optionsWithTime"
            name="dateStart"
            autocomplete="off"
            v-validate="'required'"
          />
          <small v-show="errors.has('dateStart')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
        <div
          class="form-group col-sm-3"
          :class="{ 'has-error': errors.has('dateEnd') }"
        >
          <label>
            {{ $t("end_date") }}
          </label>
          <date-picker
            class="NeoDataPicker"
            v-model="items.dateEnd"
            :config="optionsWithTime"
            name="dateEnd"
            autocomplete="off"
            v-validate="'required'"
          />
          <small v-show="errors.has('dateEnd')" class="text-danger">
            {{ $t("field_required") }}
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div
            class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
            @click="setTime(30)"
          >
            {{ $t("last_month") }}
          </div>
          <div
            class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
            @click="setTime(90)"
          >
            {{ $t("last_3_months") }}
          </div>
          <div
            class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
            @click="setTime(180)"
          >
            {{ $t("last_6_months") }}
          </div>
          <div
            class="btn btn-primary my-1 ml-1 d-block d-md-inline-block"
            @click="setTime(365)"
          >
            {{ $t("last_12_months") }}
          </div>
        </div>
      </div>

      <div class="hr-line-dashed"></div>
      <div class="form-group row">
        <div class="col-sm-4 col-sm-offset-2">
          <button class="btn btn-primary btn-sm" type="submit">
            {{ $t("download_data") }}
          </button>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-12 px-8" v-if="statistic.all">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <td>#</td>
                <td>{{ $t("count_orders") }}</td>
                <td>{{ $t("total_gross_amount") }}</td>
                <td>{{ $t("total_net_amount") }}</td>
              </tr>
            </thead>
            <tr v-if="statistic.promo">
              <td>
                <span v-if="radioPromotion == 3">
                  <span v-if="radioProductOrCategory == 1">
                    {{ $t("products") }}:
                  </span>
                  <span v-else> {{ $t("categories") }}: </span>
                </span>
                <span v-else>{{ $t("promotion") }}:</span>
              </td>
              <td>{{ statistic.promo.order_count }}</td>
              <td>{{ statistic.promo.amount_gross | currency }}</td>
              <td>{{ statistic.promo.amount_net | currency }}</td>
            </tr>
            <tr v-if="statistic.all">
              <td>{{ $t("all") }}:</td>
              <td>{{ statistic.all.order_count }}</td>
              <td>{{ statistic.all.amount_gross | currency }}</td>
              <td>{{ statistic.all.amount_net | currency }}</td>
            </tr>
            <tr v-if="statistic.promo">
              <td>{{ $t("all-promotion") }}:</td>
              <td>
                {{ statistic.all.order_count - statistic.promo.order_count }}
              </td>
              <td>
                {{
                  (statistic.all.amount_gross - statistic.promo.amount_gross)
                    | currency
                }}
              </td>
              <td>
                {{
                  (statistic.all.amount_net - statistic.promo.amount_net)
                    | currency
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="col-12">
          <div class="row pt-2 custom-chart">
            <div class="col-12 col-md-6">
              <canvas id="id2_chart"></canvas>
            </div>
            <div class="col-12 col-md-6">
              <canvas id="id3_chart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.custom-chart {
  max-width: 1000px;
  margin: 10px auto;
}
</style>

<script>
import Chart from "chart.js/auto";

export default {
  name: "PromotionsSale",
  data() {
    return {
      radioProductOrCategory: 0,
      products: 1,
      categories: 0,
      radioPromotion: 0,
      percent: 0,
      gratis: 1,
      none: 2,
      labelSelectCategory: "name",
      items: {
        dateStart: null,
        dateEnd: null,
        categoryId: [],
        productSymbol: [],
        percent: 0,
        inlineRadioOptions: 0,
        inlineRadioProductOrCategory: 0
      },
      statistic: {},
      categoryList: [],
      productList: [],
      standardFormatDate: {
        format: "YYYY-MM-DD HH:mm:ss"
      },
      chart2: null,
      chart3: null
    };
  },
  computed: {
    urlCategoryList() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/categories`;
    },
    urlProductList() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/lists/products`;
    },
    urlStatistic() {
      return `${this.$route.params.shop_name}/${this.$route.params.shop_lang}/statistics/sales/promotions`;
    }
  },
  async created() {
    const timeEnd = this.$moment("09:00:00", "HH:mm:ss");
    const timeStart = this.$moment("08:59:00", "HH:mm:ss");
    const currentDate = this.$moment().set({
      hour: timeStart.get("hour"),
      minute: timeStart.get("minute"),
      second: timeStart.get("second")
    });
    this.items.dateEnd = currentDate.format(this.standardFormatDate.format);
    this.items.dateStart = this.$moment(this.items.dateEnd)
      .subtract(1, "months")
      .set({
        hour: timeEnd.get("hour"),
        minute: timeEnd.get("minute")
      })
      .format(this.standardFormatDate.format);
    await this.getCategoryList();
    await this.getProductList();
    this.$emit("loading", false);
  },
  methods: {
    radioRange() {
      this.items.categoryId = [];
      this.items.productSymbol = [];
    },
    radioTypeGratis() {
      this.percent = 0;
      this.items.percent = 0;
    },
    radioTypePercent() {
      this.percent = 0;
      this.items.percent = 0;
      this.items.categoryId = [];
      this.items.productSymbol = [];
    },
    radioTypeNone() {
      this.percent = 0;
      this.items.percent = 0;
      this.items.categoryId = [];
      this.items.productSymbol = [];
    },
    setTime(time) {
      let months = Math.round(time / 30);
      this.items.dateEnd = this.$moment().format(
        this.standardFormatDate.format
      );
      this.items.dateStart = this.$moment(this.dateEnd)
        .subtract(months, "months")
        .format(this.standardFormatDate.format);
    },
    getProductList() {
      return this.$http
        .get(this.urlProductList)
        .then(resp => {
          if (resp.data !== undefined) {
            this.productList = resp.data.data;
          }
          return true;
        })
        .catch(err => {
          this.$toastr.error(err);
          return false;
        });
    },
    getCategoryList() {
      return this.$http
        .get(this.urlCategoryList)
        .then(resp => {
          if (resp.data !== undefined) {
            this.categoryList = resp.data.data;
          }
          return true;
        })
        .catch(err => {
          this.$toastr.error(err);
          return false;
        });
    },
    prepareDataToSave() {
      let data = {
        dateStart: this.items.dateStart,
        dateEnd: this.items.dateEnd,
        categoryId: this.items.categoryId.join(","),
        productSymbol: this.items.productSymbol.join(","),
        percent: this.items.percent,
        inlineRadioOptions: this.radioPromotion,
        inlineRadioProductOrCategory: this.radioProductOrCategory
      };

      return data;
    },
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.chart2 !== null ? this.chart2.destroy() : (this.chart2 = null);
          this.chart3 !== null ? this.chart3.destroy() : (this.chart3 = null);
          let data = this.prepareDataToSave();
          this.$emit("loading", true);
          this.$http
            .get(this.urlStatistic, { params: data })
            .then(resp => {
              this.statistic = resp.data;
              this.chart2 = this.createChart(
                "id2_chart",
                this.prepareDataToChart2(this.result2(resp.data))
              );
              this.chart3 = this.createChart(
                "id3_chart",
                this.prepareDataToChart2(this.result3(resp.data))
              );
              this.$emit("loading", false);
            })
            .catch(err => {
              this.$toastr.error(err);
              this.$emit("loading", false);
            });
        }
      });
    },
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
      return myChart;
    },
    prepareDataToChart2(result) {
      return {
        type: "doughnut",
        data: {
          labels: result.labels,
          datasets: result.dataset
        }
      };
    },
    result2(data) {
      const labels = ["Bez promocji", "Z promocją"];
      const all = data.all.order_count - data.promo.order_count;
      const withPromo =
        Math.round(
          ((data.promo.order_count * 100) / data.all.order_count) * 100
        ) / 100;
      const withoutPromo =
        Math.round(((all * 100) / data.all.order_count) * 100) / 100;

      const dataset = [
        {
          // one line graph
          label: "Zamówienia",
          data: [withoutPromo, withPromo],
          backgroundColor: ["rgba(54, 162, 235, 1)", "rgba(201, 203, 207, 1)"]
        }
      ];

      return {
        labels,
        dataset
      };
    },
    result3(data) {
      const labels = ["Bez promocji", "Z promocją"];
      const all = data.all.amount_net - data.promo.amount_net;
      const withPromo =
        Math.round(
          ((data.promo.amount_net * 100) / data.all.amount_net) * 100
        ) / 100;
      const withoutPromo =
        Math.round(((all * 100) / data.all.amount_net) * 100) / 100;

      const dataset = [
        {
          // one line graph
          label: "Zamówienia",
          data: [withoutPromo, withPromo],
          backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(255, 159, 64, 1)"]
        }
      ];

      return {
        labels,
        dataset
      };
    }
  }
};
</script>
<style>
.thumbnail-container {
  display: flex;
  justify-content: center;
}
.select-thumbnail {
  max-width: 30px;
  max-height: 30px;
}
.product-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
